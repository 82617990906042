import { useState } from 'react'
import Socios from './Socio/socios.js'
import Ingreso from './Ingreso/ingreso.js'
import Importes from './Importes/importes.js'
import Cobranzas from './Cobranzas/cobranza.js'

export default function Menu(props) {
    const [elegido, setElegido] = useState(0)
    const opciones = [
        {nombre: 'Socios', cual: 1}, 
        {nombre: 'Ingreso', cual: 2}, 
        {nombre: 'Importes', cual: 3}, 
        {nombre: 'Cobranzas', cual: 4}, 
        {nombre: 'Disciplinas', cual: 5}, 
        {nombre: 'Listados', cual: 6}, 
        {nombre: 'Alta', cual: 7}
    ]

    function seleccionar (cual) {
        setElegido(cual)
        props.setSocio({...props.socio, id: 0})
        if (cual===7)
            window.open("https://clubrionegro.ar/alta")
    }
    
    return (<>
    <div className="flex flex-row h-full">
        <div className="bg-zinc-500 text-lg h-full w-1/12">
            <div className="flex flex-col p-2 justify-between">
                   {opciones.map (a => {
                        if ((a.cual===1 || a.cual===3 || a.cual===5 || a.cual===6) && props.datos_u.rol!==3) return
                        return (<>
                        {elegido !== a.cual ?
                            <button 
                               className="bg-gray-200 hover:bg-gray-800 hover:text-white rounded-xl p-2 my-2"
                               onClick={() => seleccionar(a.cual)}
                               key={a.cual}
                            >
                            {a.nombre}
                            </button>
                        :
                            <button 
                               className="bg-gray-800 text-white rounded-xl p-2 my-2"
                               onClick={() => seleccionar(a.cual)}
                               key={a.cual}
                            >
                            {a.nombre}
                            </button>
                       }
                    </>)}
                 )}
            </div>
            </div>
            <div className="w-11/12">
                {elegido===1 ? <Socios back={props.back} token={props.token} actividades={props.actividades} socio={props.socio} setSocio={props.setSocio} socios={props.socios}/> : ''}
                {elegido===2 ? <Ingreso back={props.back} token={props.token} actividades={props.actividades} socios={props.socios} socio={props.socio} setSocio={props.setSocio}/> : ''}
                {elegido===3 ? <Importes back={props.back} token={props.token} actividades={props.actividades} setElegido={setElegido} /> : ''}
                {elegido===4 ? <Cobranzas back={props.back} token={props.token} actividades={props.actividades} socio={props.socio} setSocio={props.setSocio} setElegido={setElegido} socios={props.socios}/> : ''}
                {elegido===5 ? <div className="flex flex-row items-center justify-center">Disciplinas</div> : ''}
                {elegido===6 ? <div className="flex flex-row items-center justify-center">Listados</div>  : ''}
            </div>
        </div>
    </>)
}