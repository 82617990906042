export default function ControlesSocio (props) {

    function guardar () {
        if (props.file !== null) {
            var data = new FormData()
            data.append('img', props.file)
            var user = {
                body: data,
                method: 'POST'
            }
            fetch(props.back + 'subir_imagen',user)
            .then (response => response.json())
            .then (json => guardar_resto(json.filename))
        } else
            guardar_resto('')
    }

    function guardar_resto (ima) {
        var a = props.socio
        a = {...a, nacimiento: new Date(a.nacimiento), alta_sistema: new Date(a.alta_sistema)}
        if(ima !== '') 
            a = {...a, imagen: ima} 
        fetch(props.back + 'admin/traer_grupo_fam/id/' + a.id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())
        .then (json => {
            const ids = json.grupo_fam_lis
            actualizar_grupo_familiar(ids,props.socio.grupo_fam_lis, props.socio.id)
        })
        
        fetch(props.back + 'admin/update', {
            body: JSON.stringify(a),
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())    
        .then(json => {
            props.setSocio({id: 0})
            props.setRecargar(!props.recargar)
        })
    }

function actualizar_grupo_familiar(ids, nuevo, id_nuevo) {
    const data =  ids.split(',')
    const data_n = nuevo.split(',')
    const final1 = data.map(t => {
        return {id: t, quien: ''}
    })
    var final = final1
    if (data_n.length) {
        const final2 = data_n.map(t => {
            return {id: t, quien: id_nuevo}
        })
        final = final1.concat(final2)
    }
    console.log(final)
    if (! final.length) return
    const pedidosPausados = final.map(t => {
        return () => {
            if (parseInt(t.id)) {
                fetch (props.back + 'admin/actualizar_grupo_fam', {
                    body: JSON.stringify(t),
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + props.token,
                        'Content-Type': 'application/json' 
                    }
                })
                .then (response => response.json())
                .then (a => {})
            }
        }
    })
    pedidosPausados.reduce((p, pnew) => p.then(() => sleep(100)).then(pnew), Promise.resolve());
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
}
       

    return (<>
    <div className="flex flex-row justify-center">
        <button
            className="my-2 p-2 mx-10 rounded-xl bg-green-200 hover:bg-green-800 hover:text-white"
            onClick={e => guardar()}
        >
            Guardar Cambios
        </button>
        <button
            className="my-2 p-2 mx-10 rounded-xl bg-red-200 hover:bg-red-800 hover:text-white"
            onClick={e => props.setSocio({id:0})}
        >
             Descartar Cambios
        </button>
    </div>
    </>)
}